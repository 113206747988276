import React from "react";
import SquareCard from "../cardComponents/SquareCard";
import { squareCardData } from "../../data/squareCardData";
import RectangleCard from "../cardComponents/RectangleCard";
import { rectangleCardData } from "../../data/rectangleCardData";
const TopCardComponet = () => {
  return (
    <div className="flex flex-col flex-1 lg:flex-row">
      <div className="flex justify-between flex-1  lg:flex-none flex-wrap">
        <SquareCard {...squareCardData[0]} />
        <SquareCard {...squareCardData[1]} />
      </div>
      <div className="w-full">
      <RectangleCard {...rectangleCardData[0]} />
      </div>
    </div>
  );
};

export default TopCardComponet;
