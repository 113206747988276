import React from "react";

const RectangleCard = ({
  bgColor,
  logo,
  name,
  btnText,
  imgArr,
  flexdir,
  id,
  linkTo,
}) => {
  return (
    <div
      id={id}
      className={`flex flex-1 items-center m-mobile sm:m-tablet lg:m-laptop justify-between  border-[1px] rounded-[1rem] border-[#d2590054] `}
      style={{
        backgroundColor: bgColor,
        flexDirection: flexdir,
      }}
    >
      <div className="flex w-box-width  aspect-square gap-5 sm:gap-[3rem] flex-col justify-between  p-[.9rem] sm:p-[1.5rem] lg:h-[9.3rem] lg:w-[9.3rem] m-0" style={{width:flexdir?'100%':''}}>
        <div className="w-full flex flex-col gap-[3.5vw]  lg:gap-[.1rem] ">
          <img
            src={logo}
            alt={name}
            className="w-[13vw]  lg:w-[3rem] rounded-lg aspect-square"
          />
          <p className="capitalize  relative text-[3.2vw] lg:text-[.75rem]">
            {name}
          </p>
        </div>
        <a
          href={linkTo}
          target="_blank" rel="noreferrer"
          className="bg-[#D25900] cursor-pointer text-center w-[20vw] lg:w-[4.6rem] py-[.5rem] sm:py-[.8rem] whitespace-nowrap rounded-[.4rem] capitalize text-[3vw] lg:text-[.65rem]  text-white font-semibold shadow-2xl"
          style={{
            boxShadow:
              "0px 2.6586105823516846px 11.299095153808594px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          {btnText}
        </a>
      </div>
      <div className="flex w-box-width aspect-square items-center gap-5 sm:gap-[3rem] flex-col justify-center lg:h-[9.3rem] lg:w-[9.3rem] m-0">
        <div
          className="grid gap-2 grid-cols-2  grid-rows-2 place-items-center h-[30vw] lg:h-[7rem]  self-center
          aspect-square "
        >
          {imgArr.map((item, i) => (
            <img
              src={item}
              key={i}
              alt={name}
              className="aspect-square w-[85%] lg:w-[3rem]"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RectangleCard;
