import React from "react";
import vivahLogoText from '../assets/logo/vivahlgBlack.png'

const Textbox = ({ logo, text, fontWeight,email}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <img src={logo} alt="text" className="w-[5.9rem] sm:w-[20rem]  lg:w-[15rem] aspect-square" />
      <img src={vivahLogoText} className="w-[13rem] sm:w-[16rem] mb-2" />
      {/* <h3 className={`uppercase translate-y-[-.4rem] text-[.55rem] sm:text-[1.6rem] lg:text-[1.3rem] `} style={{ fontWeight }}>
        {text}
      </h3> */}
      <a href={"mailto:"+email} target='_blank' rel="noreferrer" className="font-semibold cursor-pointer text-[.7rem] sm:text-[.9rem] lg:text-[.8rem]">{email}</a>
    </div>
  );
};

export default Textbox;
