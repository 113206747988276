import React from "react";

const SquareCard = ({ bgColor, logo, name, btnText,boldTag,linkTo,squareId}) => {
  return (
    <div
    id={squareId}
      className="flex min-w-box-width  lg:min-w-fit  flex-1  aspect-square gap-5 sm:gap-[3rem] m-mobile sm:m-tablet lg:m-laptop flex-col justify-between border-[.6px] rounded-[1rem] border-[#d2590054] p-[.9rem] sm:p-[1.5rem] lg:h-[9.3rem] lg:w-[9.3rem]"
      style={{ backgroundColor: bgColor,width:'initial' }}
    >
      <div className="w-full flex flex-col gap-[2vw]  lg:gap-[.1rem]">
        <img src={logo} alt={name} className="w-[13vw]  lg:w-[3rem] rounded-lg aspect-square" />
        <p className="capitalize  relative text-[3.2vw] lg:text-[.75rem]">
          {name}
        {boldTag?<span className="absolute bottom-[-100%] font-bold left-0">{boldTag}</span>:""}
        </p>
      </div>
      <a href={linkTo} target="_blank" rel="noreferrer"
        className="bg-[#D25900] cursor-pointer text-center w-[20vw] lg:w-[4.6rem] py-[.5rem] sm:py-[.8rem] whitespace-nowrap rounded-[.4rem] capitalize text-[3vw] lg:text-[.65rem]  text-white font-semibold shadow-2xl"
        style={{
          boxShadow:
            "0px 2.6586105823516846px 11.299095153808594px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {btnText}
      </a>
    </div>
  );
};

export default SquareCard;
