import React from "react";
import SquareCard from "../cardComponents/SquareCard";
import { squareCardData } from "../../data/squareCardData";
import RectangleCard from "../cardComponents/RectangleCard";
import { rectangleCardData } from "../../data/rectangleCardData";
const BottomCardComponent = () => {
  return (
    <div className="flex flex-col justify-between w-full">
      <div className="flex lg:flex-none justify-between">
        <SquareCard {...squareCardData[4]} />
        <SquareCard {...squareCardData[5]} />
      </div>
      <div>
        <RectangleCard {...rectangleCardData[2]} />
      </div>
    </div>
  );
};

export default BottomCardComponent;
