import instagramLogo from "../assets/logo/instagram.png";
import whatsappLogo from "../assets/logo/whatsapp 1.png";
import googleLogo from "../assets/logo/google.png";
import indiamartLogo from "../assets/logo/indiamart.png";
import twitterLogo from "../assets/logo/twitter.png";
import mapLogo from "../assets/logo/maps.png";

export const squareCardData = [
  {
    name: "instagram",
    logo: instagramLogo,
    bgColor: "#FFFFFF",
    btnText: "follow",
    linkTo:'https://www.instagram.com/vivah_creation/?hl=en',
    squareId:'square1'
  },
  {
    name: "whatsApp",
    logo: whatsappLogo,
    bgColor: "rgba(100, 177, 97, 0.09)",
    btnText: "chat now",
    linkTo:'https://api.whatsapp.com/send?phone=919320838762',
    squareId:'square2'
  },
  {
    name: "review us",
    boldTag: "on Google",
    logo: googleLogo,
    bgColor: "#FFFFFF",
    btnText: "Rate us",
    linkTo:'https://g.page/r/CZAhjZKAIuFWEAg/review',
    squareId:'square3'
  },
  {
    name: "IndiaMart",
    logo: indiamartLogo,
    bgColor: "#FFFFFF",
    btnText: "visit",
    linkTo:'https://www.vivahcreation.net/',
    squareId:'square4'
  },
  {
    name: "twitter",
    logo: twitterLogo,
    bgColor: "rgba(0, 0, 0, 0.03)",
    btnText: "follow",
    linkTo:'https://twitter.com/vivah_creation',
    squareId:'square5'
  },
  {
    name: "vivah creation",
    logo: mapLogo,
    bgColor: "#FFFFFF",
    btnText: "locate",
    linkTo:'https://www.google.com/maps/place/Vivah+Creation/@18.9504371,72.8313083,15z/data=!4m5!3m4!1s0x0:0x4f4f11cd4e9e043b!8m2!3d18.9504295!4d72.8313111',
    squareId:'square6'
  },
];
