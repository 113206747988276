import Vivah from "./page/Vivah";

function App() {
  return (
    <div className="App grid place-items-center">
      <Vivah />
    </div>
  );
}

export default App;
